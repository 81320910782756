footer {
  background-color: $yellow;
  border-bottom: 2px solid $light-grey;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border-top: 1px solid $light-grey;

  ul {
    display: flex;
    justify-content: flex-end;
    

    li {
      color: $main-color;
      margin-left: 25px;
      font-size: 1.3rem;
      &:hover {
        color: $light-grey;
      }
    }
  }
}
