.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 0 auto;

  li.is-active a {
    border-bottom-color: $main-color;
    color: $main-color; }

  a {
    border: none;
    color: $yellow;
  }
}


.sl-form {
  padding-top: 30px;
}

.control {
  font-size: 1.2rem;
}
 

input {
  border-radius: 5px;
  border: 1px solid $light-grey;
  padding: 5px;
  &:focus {
    border-color: $main-color;
  }
}
 
.button.is-link {
  background-color: $main-color;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  &:hover {
    background-color: white;
    color: $main-color;
    border: 1px solid $main-color;
  }
}